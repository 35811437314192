export default function FriendsListTab({
    handleShowAddressList,
    showTrustedAddressList,
    setShowTrustedAddressList,
  }) {
    return (
      <div className="flex flex-row justify-between items-center px-[2vw]">
        <button onClick={() => setShowTrustedAddressList(true)}>
          <code
            className={
              "text-lg font-semibold " +
              (!showTrustedAddressList ? "opacity-50" : "")
            }
          >
            Chats
          </code>
        </button>
        <button onClick={handleShowAddressList}>
          <code
            className={
              "flex flex-row item text-lg font-semibold " +
              (showTrustedAddressList ? "opacity-50" : "")
            }
          >
            Unknown chats
          </code>
        </button>
      </div>
    );
  };