// import * as dotenv from "dotenv";
import Modal from "react-modal";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import EthCrypto from "eth-crypto";
import { useAccount, useProvider, useNetwork, useSigner } from "wagmi";
import { Oval } from "react-loader-spinner";
import moment from "moment";

import {
  usdcIconSVG,
  ethereumIconSVG,
  dropdownIconSVG,
  cancelIconSVG,
} from "../../../assets";
import { theGraphClient } from "../../../config";
import { ContractInstance } from "../../../hooks";
import { GQL_QUERY_GET_COMMUNICATION_ADDRESS } from "../../../constants";
import { CONTRACT_META_DATA } from "../../../constants";
import { getETHPrice } from "../../../utils";

import "./peerToPeer.css";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "1.5rem",
  },
  overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
};

export default function LendingOfferModal({
  openModal,
  toggleOpenModal,
  activeReceiverAddress,
  messages,
  setMessageLog,
  messagesState,
  setMessagesState,
}) {
  const { address } = useAccount();
  const provider = useProvider();
  const { chain } = useNetwork();
  const { data: signer } = useSigner();

  const [isLoading, setLoading] = useState(false);
  const [loadingStep, setLoadingStep] = useState(1);
  const [ETH_USD, setETH_USD] = useState(0);
  const [amtOfToken, setAmtOfToken] = useState(0);
  const [openTokenTypeDropdown, setOpenTokenTypeDropdown] = useState(false);
  const [interestRate, setInterestRate] = useState(0);
  const [maturityDay, setMaturityDay] = useState(0);
  const [USDCBalance, setUSDCBalance] = useState(0);
  const [WETHBalance, setWETHBalance] = useState(0);
  const [supplyWETH, setSupplyWETH] = useState(true);

  const handleTokenChange = (e) => setAmtOfToken(e.target.value);
  const handleInterestRateChange = (e) => setInterestRate(e.target.value);
  const handleMaturityDayChange = (e) => setMaturityDay(e.target.value);
  const toggleOpenTokenTypeDropdown = () =>
    setOpenTokenTypeDropdown(!openTokenTypeDropdown);
  const toggleSupplyWETH = () => setSupplyWETH(!supplyWETH);

  const contracts = ContractInstance();

  useEffect(() => {
    // TODO: this useEffect is fired twice at init this shouldn't
    // If not activeReceiver selected do not fire
    if (!Boolean(activeReceiverAddress)) {
      return;
    }

    getETHPrice(setETH_USD);
    getUSDCBalance();
    getWETHBalance();
  });

  const getWETHBalance = async () => {
    setWETHBalance(
      parseFloat(
        ethers.utils.formatEther(
          (await contracts.contractWETH.balanceOf(address)).toString()
        )
      ).toFixed(2)
    );
  };

  const getUSDCBalance = async () => {
    setUSDCBalance(
      parseFloat(
        ethers.utils.formatEther(
          (await contracts.contractUSDC.balanceOf(address)).toString()
        )
      ).toFixed(2)
    );
  };

  const getWETH = async () => {
    await contracts.contractWETH.getWETH();
  };

  const getUSDC = async () => {
    await contracts.contractUSDC.mint(address, ethers.utils.parseEther("1000"));
  };

  const increaseAllowance = async () => {
    const _operator = contracts.contractLendingFactory.address;
    const contractToken = await (supplyWETH
      ? contracts.contractWETH
      : contracts.contractUSDC);
    const tx = await contractToken
      .approve(_operator, ethers.utils.parseEther(amtOfToken))
      .catch(() => {
        setLoading(false);
        setLoadingStep(1);
      });

    await tx.wait().then(() => setLoadingStep(1));
  };

  const signExchangeOffer = async () => {
    const domain = {
      name: "LendingFactory",
      chainId: chain.id,
      verifyingContract: contracts.contractLendingFactory.address,
    };

    const types = {
      Offer: [
        { name: "borrower", type: "address" },
        { name: "lender", type: "address" },
        { name: "lenderToken", type: "address" },
        { name: "borrowerToken", type: "address" },
        { name: "chainlinkLenderFeed", type: "address" },
        { name: "chainlinkBorrowerFeed", type: "address" },
        { name: "totalCollateral", type: "uint256" },
        { name: "loanAmount", type: "uint256" },
        { name: "loanDuration", type: "uint256" },
        { name: "interestRate", type: "uint256" },
      ],
    };

    let data;
    const contractMetadata = CONTRACT_META_DATA[chain.id];
    if (supplyWETH) {
      data = {
        borrower: activeReceiverAddress,
        lender: address,
        lenderToken: contractMetadata.contractWETH,
        borrowerToken: contractMetadata.contractUSDC,
        chainlinkLenderFeed: contractMetadata.oracleWETH,
        chainlinkBorrowerFeed: contractMetadata.oracleUSDC,
        loanAmount: ethers.utils.parseEther(amtOfToken),
        totalCollateral: ethers.utils.parseEther(
          (parseFloat(amtOfToken) * parseInt(ETH_USD) * 2).toString()
        ),
        loanDuration: parseInt(maturityDay * 86400),
        interestRate: ethers.utils
          .parseEther(interestRate)
          .div(365)
          .mul(maturityDay * 10000)
          .div(10000),
      };
    } else {
      data = {
        borrower: activeReceiverAddress,
        lender: address,
        lenderToken: contractMetadata.contractUSDC,
        borrowerToken: contractMetadata.contractWETH,
        chainlinkLenderFeed: contractMetadata.oracleUSDC,
        chainlinkBorrowerFeed: contractMetadata.oracleWETH,
        loanAmount: ethers.utils.parseEther(amtOfToken),
        totalCollateral: ethers.utils.parseEther(
          ((parseFloat(amtOfToken) / parseInt(ETH_USD)) * 2).toString()
        ),
        loanDuration: parseInt(maturityDay * 86400),
        interestRate: ethers.utils
          .parseEther(interestRate)
          .div(365)
          .mul(maturityDay * 10000)
          .div(10000),
      };
    }

    let digest = await signer._signTypedData(domain, types, data).catch(() => {
      setLoading(false);
      setLoadingStep(1);
    });

    setLoadingStep(2);

    let { v, r, s } = ethers.utils.splitSignature(digest);

    const metadata = {
      ...data,
      v: v,
      r: r,
      s: s,
    };

    return metadata;
  };

  const handleSendOffer = async () => {
    await increaseAllowance();
    let senderMessage = await signExchangeOffer();
    senderMessage = JSON.stringify(senderMessage);

    let senderPublicKey = JSON.parse(
      localStorage.getItem("public-communication-address")
    );

    senderPublicKey = senderPublicKey[address][chain.id];

    const graphClient = theGraphClient();

    const sendMessage = async (activeReceiverAddress) => {
      // TODO: sanitize graphQL queries b/c currently dynamic and exposes injection vulnerability

      // Query for the receiver's communication public key
      const data = await graphClient
        .query(GQL_QUERY_GET_COMMUNICATION_ADDRESS, {
          receiverAddress: activeReceiverAddress,
        })
        .toPromise();
      const receiverPublicKey = data.data.identities[0].communicationAddress;

      let messageEncryptedSender = await EthCrypto.encryptWithPublicKey(
        senderPublicKey,
        senderMessage
      );
      let messageEncryptedReceiver = await EthCrypto.encryptWithPublicKey(
        receiverPublicKey,
        senderMessage
      );

      messageEncryptedSender = EthCrypto.cipher.stringify(
        messageEncryptedSender
      );
      messageEncryptedReceiver = EthCrypto.cipher.stringify(
        messageEncryptedReceiver
      );

      const tx = await contracts.contractEcho
        .logMessage(
          "2",
          address,
          activeReceiverAddress,
          messageEncryptedSender,
          messageEncryptedReceiver
        )
        .catch(() => {
          setLoading(false);
          setLoadingStep(1);
        });

      setLoadingStep(3);

      await tx.wait().then(() => {
        toggleOpenModal();
        setLoadingStep(1);
        setLoading(false);
      });
    };

    const newMessageState = {
      ...messagesState,
      [activeReceiverAddress]: false,
    };

    // Sends transaction to blockchain
    sendMessage(activeReceiverAddress, messages).catch((err) => {
      console.log("Sending Message Error:", err);
      // TODO: make message indicative of error by changing color

      let newReceiverMessageLog = [
        {
          from: address,
          message: "Error: Address likely doesn't have a communication address",
          timestamp: `${moment().unix()}`,
        },
      ];

      if (
        Object.keys(messages).length !== 0 ||
        activeReceiverAddress in messages
      ) {
        newReceiverMessageLog = [
          ...messages[activeReceiverAddress],
          ...newReceiverMessageLog,
        ];
      } else {
        newReceiverMessageLog = [newReceiverMessageLog];
      }

      const newMessageLog = messages;
      newMessageLog[activeReceiverAddress] = newReceiverMessageLog;
      setMessageLog(newMessageLog);
      setMessagesState(newMessageState);
    });
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => {
        setLoading(false);
        setSupplyWETH(true);
        setAmtOfToken(0);
        setLoadingStep(1);
        toggleOpenModal();
      }}
      style={modalStyles}
    >
      {isLoading ? (
        <div className="flex flex-col w-[384px] items-center justify-center gap-[20px]">
          <Oval
            ariaLabel="loading-indicator"
            height={40}
            width={40}
            strokeWidth={3}
            strokeWidthSecondary={3}
            color="black"
            secondaryColor="white"
          />
          <div className="text-xl font-medium">
            Sending offer {loadingStep}/3...
          </div>
        </div>
      ) : (
        <div className="flex flex-col py-4 px-4 gap-6">
          <div className="flex flex-row justify-between items-center">
            <code className="text-2xl">Make lending offer.</code>
            <div className="flex flex-row gap-[1px]">
              <button
                className="bg-[#333333] text-white p-1 hover:bg-[#555555] rounded-l-[8px]"
                onClick={getWETH}
              >
                Get WETH
              </button>
              <button
                className="bg-[#333333] text-white p-1 hover:bg-[#555555] rounded-r-[8px]"
                onClick={getUSDC}
              >
                Get USDC
              </button>
              <button
                className="flex items-center pl-3"
                onClick={toggleOpenModal}
              >
                <img className="h-[16px]" src={cancelIconSVG} alt=""></img>
              </button>
            </div>
          </div>
          <div className="flex">
            <button
              className={
                "flex justify-center items-center rounded-l-[8px] w-[150px] gap-2 hover:bg-[#eeeeee] " +
                (openTokenTypeDropdown ? "rounded-bl-[0px]" : "")
              }
              onClick={toggleOpenTokenTypeDropdown}
            >
              {supplyWETH ? (
                <div className="flex gap-2 font-bold">
                  <img className="h-6" src={ethereumIconSVG} alt=""></img>
                  WETH
                </div>
              ) : (
                <div className="flex gap-2 font-bold">
                  <img className="h-6" src={usdcIconSVG} alt=""></img>USDC
                </div>
              )}
              <img src={dropdownIconSVG} alt=""></img>
            </button>
            <div className="flex flex-row">
              <input
                placeholder="0"
                onChange={handleTokenChange}
                className="code w-[400px] px-4 py-3 rounded-l-[8px] bg-[#f2f2f2]"
              ></input>
              <code className="flex items-center justify-center rounded-r-[8px] w-[130px] bg-[#f2f2f2] text-[#999999]">
                ≈
                {amtOfToken
                  ? supplyWETH
                    ? (parseFloat(amtOfToken) * parseFloat(ETH_USD)).toFixed(2)
                    : amtOfToken
                  : 0}{" "}
                USD
              </code>
            </div>
          </div>
          {openTokenTypeDropdown ? (
            <div className="flex flex-col place-self-start">
              <button
                className="flex justify-center items-center rounded-b-[8px] w-[150px] py-[13px] mt-[-17px] gap-2 hover:bg-[#eeeeee]"
                onClick={() => {
                  toggleSupplyWETH();
                  toggleOpenTokenTypeDropdown();
                }}
              >
                {supplyWETH ? (
                  <>
                    <img className="h-6" src={usdcIconSVG} alt=""></img>USDC
                  </>
                ) : (
                  <>
                    <img className="h-6" src={ethereumIconSVG} alt=""></img>
                    WETH
                  </>
                )}
              </button>
            </div>
          ) : (
            <></>
          )}
          <code className="text-sm">
            Balance: {supplyWETH ? WETHBalance : USDCBalance}
          </code>
          <div className="flex">
            <div className="flex items-center justify-center w-[150px] font-bold">
              Supply APY
            </div>
            <div className="flex flex-row">
              <input
                placeholder="0"
                onChange={handleInterestRateChange}
                className="code w-[400px] px-4 py-3 rounded-l-[8px] bg-[#f2f2f2]"
              ></input>
              <code className="flex items-center justify-center rounded-r-[8px] w-[130px] bg-[#f2f2f2] text-[#999999]">
                %
              </code>
            </div>
          </div>
          <div className="flex">
            <div className="flex items-center justify-center w-[150px] font-bold">
              Maturity days
            </div>
            <div className="flex flex-row">
              <input
                placeholder="0"
                onChange={handleMaturityDayChange}
                className="code w-[400px] px-4 py-3 rounded-l-[8px] bg-[#f2f2f2]"
              ></input>
              <code className="flex items-center justify-center rounded-r-[8px] w-[130px] bg-[#f2f2f2] text-[#999999]">
                days
              </code>
            </div>
          </div>
          <button
            className="bg-[#000000] rounded-[40px] text-white py-[10px] disabled:opacity-50 font-bold"
            onClick={async () => {
              setLoading(true);
              handleSendOffer();
              setAmtOfToken();
            }}
            disabled={
              amtOfToken &&
                interestRate <= 100 &&
                interestRate &&
                parseInt(maturityDay * 86400) >= 1 &&
                maturityDay &&
                parseFloat(amtOfToken) <=
                (supplyWETH ? parseFloat(WETHBalance) : parseFloat(USDCBalance))
                ? false
                : true
            }
          >
            Send Offer
          </button>
        </div>
      )}
    </Modal>
  );
}
