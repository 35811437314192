import { ETH_USD_API_ENDPOINT } from "../constants";

const getETHPrice = async (setPrice) => {
  // TODO: add to constants - also is this URL safe to hardcode? what if it changes?
  fetch(ETH_USD_API_ENDPOINT)
    .then((response) => response.json())
    .then((data) => {
      setPrice(data.USD);
    });
};

export default getETHPrice;
