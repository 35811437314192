import { useEffect, useState } from "react";
import { useAccount, useNetwork } from "wagmi";

import {
  GQL_QUERY_GET_COMMUNICATION_ADDRESS,
  GQL_QUERY_GET_UNKNOWN_SENDERS,
  BURNER_ADDRESS,
} from "../../../constants";
import { ContractInstance, useTheGraphClient } from "../../../hooks";
import Friend from "./Friend";
import UnknownFriend from "./UnknownFriend";
import EmptyFriendsList from "./EmptyFriendsList";
import FriendsListTab from "./FriendsListTab";

export default function FriendsList({
  chatAddresses,
  setChatAddresses,
  activeIndex,
  setActiveIndex,
  setActiveReceiver,
  unknownChatAddresses,
  setUnknownChatAddresses,
  showTrustedAddressList,
  setShowTrustedAddressList,
}) {
  const { address } = useAccount();
  const { chain } = useNetwork();

  const [friendsListPFP, setFriendsListPFP] = useState([]);

  const contracts = ContractInstance();

  const getPFP = async (userAddress) => {
    return await contracts.contractPFP.getProfilePicture(userAddress);
  };

  // TODO: combine the useEffects?
  useEffect(() => {
    if (
      address in unknownChatAddresses &&
      unknownChatAddresses[address].length > 0
    ) {
      setActiveIndex(0);
      setActiveReceiver(
        Boolean(unknownChatAddresses) && address in unknownChatAddresses
          ? unknownChatAddresses[address][0]
          : BURNER_ADDRESS
      );
    }
  }, [showTrustedAddressList]);

  useEffect(() => {
    async function setupProfilePictures() {
      const tempFriendsListPFP = [];
      let tempChatAddresses = unknownChatAddresses;
      if (showTrustedAddressList) {
        tempChatAddresses = chatAddresses;
      }

      if (address in tempChatAddresses) {
        for (let idx = 0; idx < tempChatAddresses[address].length; idx++) {
          tempFriendsListPFP.push(
            await getPFP(tempChatAddresses[address][idx])
          );
        }
        setFriendsListPFP(tempFriendsListPFP);
      }
    }

    setupProfilePictures();
  }, [chatAddresses, unknownChatAddresses, showTrustedAddressList]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const graphClient = useTheGraphClient();

  const handleActiveReceiver = (e, index, address) => {
    setActiveIndex(index);
    setActiveReceiver(address);
  };

  const handleShowAddressList = async () => {
    const dataIdentity = await graphClient
      .query(GQL_QUERY_GET_COMMUNICATION_ADDRESS, { receiverAddress: address })
      .toPromise();
    const dataIdentityTimestamp = dataIdentity.data.identities[0].timestamp;

    let currentChatAddresses = chatAddresses[address];

    if (currentChatAddresses == null || currentChatAddresses.length === 0) {
      currentChatAddresses = [""];
    }

    const dataMessages = await graphClient
      .query(GQL_QUERY_GET_UNKNOWN_SENDERS, {
        knownSenders: currentChatAddresses,
        receiverAddress: address,
        recentIdentityTimestamp: dataIdentityTimestamp,
      })
      .toPromise();

    const dataMessagesParsed = dataMessages.data.messages;

    if (dataMessagesParsed == null) {
      return;
    }

    const unknownAddresses = {};
    for (let idx = 0; idx < dataMessagesParsed.length; idx++) {
      unknownAddresses[dataMessagesParsed[idx].from] = true;
    }

    // keep in this format to stay consistent with chat addresses + caching potential
    const newUnknownChatAddresses = Object.assign(unknownChatAddresses, {
      [address]: Object.keys(unknownAddresses),
    });

    setUnknownChatAddresses(newUnknownChatAddresses);
    setShowTrustedAddressList(false);
  };

  const handleAddAddress = (index, friendAddress) => {
    let unknownAddressesTemp = Object.assign({}, unknownChatAddresses);

    if (index >= 0) {
      setChatAddresses((current) => {
        const chatAddressesTemp = Object.assign({}, current);

        if (
          Object.keys(chatAddresses).length !== 0 &&
          address in chatAddresses
        ) {
          chatAddressesTemp[address].push(friendAddress);
          unknownAddressesTemp[address] = unknownAddressesTemp[address].filter(
            (item) => item !== friendAddress
          );

          setUnknownChatAddresses(unknownAddressesTemp);
        } else {
          chatAddressesTemp[address] = [friendAddress];
        }

        return chatAddressesTemp;
      });

      setShowTrustedAddressList(true);
    }
  };

  const handleRemoveAddress = (index, friendAddress) => {
    if (index >= 0) {
      setChatAddresses((current) => {
        let chatAddressesTemp = Object.assign({}, current);
        if (
          Object.keys(chatAddressesTemp).length !== 0 &&
          address in chatAddressesTemp
        ) {
          chatAddressesTemp[address] = chatAddressesTemp[address].filter(
            (item) => item !== friendAddress
          );
        }

        return chatAddressesTemp;
      });
    }
  };

  return (
    <>
      <div className="border-r-[3px] border-[#333333] border-opacity-10 w-[30%] pt-[4vh]">
        <FriendsListTab
          handleShowAddressList={handleShowAddressList}
          showTrustedAddressList={showTrustedAddressList}
          setShowTrustedAddressList={setShowTrustedAddressList}
        />
        <ul className="Receivers">
          {showTrustedAddressList ? (
            address in chatAddresses && chatAddresses[address].length > 0 ? (
              chatAddresses[address].map((friendAddress, index) => {
                return (
                  <Friend key={index} index={index} activeIndex={activeIndex} handleActiveReceiver={handleActiveReceiver} handleRemoveAddress={handleRemoveAddress} friendAddress={friendAddress} friendsListPFP={friendsListPFP} />
                );
              })
            ) : (
              <EmptyFriendsList />
            )
          ) : (
            <>
              {unknownChatAddresses[address].length > 0 ? (
                unknownChatAddresses[address].map((friendAddress, index) => {
                  return (
                    <UnknownFriend key={index} index={index} activeIndex={activeIndex} handleActiveReceiver={handleActiveReceiver} handleAddAddress={handleAddAddress} friendAddress={friendAddress} friendsListPFP={friendsListPFP} />
                  );
                })
              ) : (
                <EmptyFriendsList />
              )}
            </>
          )}
        </ul>
      </div>
    </>
  );
}
