export default function EmptyFriendsList() {
  return (
    <div className="flex flex-col justify-center text-center border-[#333333] border-opacity-10">
      <code>
        You have no chats, anon <br />
        ¯\_(ツ)_/¯
      </code>
    </div>
  );
};

