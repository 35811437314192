
import { addressEllipsePNG, cancelIconSVG } from "../../../assets";

export default function Friend({ index, activeIndex, handleActiveReceiver, handleRemoveAddress, friendAddress, friendsListPFP }) {
    return (
      <button
        className="w-[80%] flex flex-row justify-between items-center px-4 py-4 font-bold rounded-[50px]"
        key={index}
        id={index === activeIndex ? "active" : "inactive"}
        onClick={(event) =>
          handleActiveReceiver(event, index, friendAddress)
        }
      >
        <code className="flex flex-row items-center gap-4 text-lg">
          <img
            className="h-10 rounded-[30px]"
            src={
              Boolean(friendsListPFP[index])
                ? friendsListPFP[index]
                : addressEllipsePNG
            }
            alt=""
          ></img>
          {`${friendAddress.substring(
            0,
            4
          )}...${friendAddress.substring(38)}`}
        </code>
        <a
          onClick={() => handleRemoveAddress(index, friendAddress)}
        >
          <img
            className="h-6 p-1 hover:bg-[#ffffff] rounded-[50px]"
            src={cancelIconSVG}
            alt=""
          ></img>
        </a>
      </button>
    )
  }