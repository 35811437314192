import { useAccount, useProvider, useSigner } from "wagmi";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import Chat from "./Chat";

import { ContractInstance } from "../../../hooks";
import ERC20JSON from "../../../contracts/ERC20.json";
import ERC721JSON from "../../../contracts/ERC721.json";
import LendingJSON from "../../../contracts/Lending.json";
import { BURNER_ADDRESS } from "../../../constants";
import { getETHPrice } from "../../../utils";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "1.5rem",
  },
  overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
};

const ChatBox = ({ activeReceiverAddress, messages, setMessageLog }) => {
  const { address } = useAccount();
  const provider = useProvider();
  const { data: signer } = useSigner();

  const [ETH_USD, setETH_USD] = useState(0);
  const [currentETHTime, setCurrentETHTime] = useState(0);
  const [error, setError] = useState("");

  const contracts = ContractInstance();

  const getCurrentETHTime = async () => {
    setCurrentETHTime((await provider.getBlock()).timestamp);
  };

  const approveNFTTransfer = async (NFTAddress, tokenId) => {
    const NFTContract = new ethers.Contract(NFTAddress, ERC721JSON.abi, signer);

    const tx = await NFTContract.approve(
      contracts.contractRequestNFT.address,
      tokenId
    );

    await tx.wait();
  };

  const acceptNFTOffer = async (
    tokenAmount,
    tokenId,
    timeExpiry,
    buyer,
    seller,
    tokenAddress,
    NFTAddress,
    v,
    r,
    s
  ) => {
    await approveNFTTransfer(NFTAddress, tokenId);

    const data = {
      tokenAmount: tokenAmount,
      tokenId: tokenId,
      timeExpiry: timeExpiry,
      buyer: buyer,
      seller: seller,
      tokenAddress: tokenAddress,
      NFTAddress: NFTAddress,
    };

    const tx = await contracts.contractRequestNFT.exchange(data, v, r, s);

    await tx.wait();
  };

  const cancelOffer = async (
    tokenAmount,
    tokenId,
    timeExpiry,
    buyer,
    seller,
    tokenAddress,
    NFTAddress,
    v,
    r,
    s
  ) => {
    const data = {
      tokenAmount: tokenAmount,
      tokenId: tokenId,
      timeExpiry: timeExpiry,
      buyer: buyer,
      seller: seller,
      tokenAddress: tokenAddress,
      NFTAddress: NFTAddress,
    };

    const tx = await contracts.contractRequestNFT.cancelOffer(data, v, r, s);

    await tx.wait().catch((err) => setError("*" + err.reason));
  };

  const updateNFTStatus = async () => {
    if (Object.keys(messages) > 0 && activeReceiverAddress in messages) {
      let tempMessages = messages;
      for (let i = 0; i < messages[activeReceiverAddress].length; i++) {
        if (messages[activeReceiverAddress][i].messageType === "1") {
          const data = {
            tokenAmount: messages[activeReceiverAddress][i].message.tokenAmount,
            tokenId: messages[activeReceiverAddress][i].message.tokenId,
            timeExpiry: messages[activeReceiverAddress][i].message.timeExpiry,
            buyer: messages[activeReceiverAddress][i].message.buyer,
            seller: messages[activeReceiverAddress][i].message.seller,
            tokenAddress:
              messages[activeReceiverAddress][i].message.tokenAddress,
            NFTAddress: messages[activeReceiverAddress][i].message.NFTAddress,
          };
          tempMessages[activeReceiverAddress][i].message.offerStatus =
            await contracts.contractRequestNFT.getOfferStatus(data);
        }
      }
      setMessageLog(tempMessages);
    }
  };

  const approveCollateralToken = async (borrowerToken, totalCollateral) => {
    const tokenContract = new ethers.Contract(
      borrowerToken,
      ERC20JSON.abi,
      signer
    );

    const tx = await tokenContract.approve(
      contracts.contractLendingFactory.address,
      totalCollateral
    );

    await tx.wait();
  };

  const acceptLendingOffer = async (
    borrower,
    lender,
    lenderToken,
    borrowerToken,
    chainlinkLenderFeed,
    chainlinkBorrowerFeed,
    totalCollateral,
    loanAmount,
    loanDuration,
    interestRate,
    v,
    r,
    s
  ) => {
    const tokenContract = new ethers.Contract(
      borrowerToken,
      ERC20JSON.abi,
      signer
    );

    if (
      Number(await tokenContract.balanceOf(address)._hex) <
      Number(totalCollateral)
    ) {
      setError("*execution reverted: ERC20: transfer amount exceeds balance");
      return;
    }

    await approveCollateralToken(borrowerToken, totalCollateral);

    const data = {
      borrower: borrower,
      lender: lender,
      lenderToken: lenderToken,
      borrowerToken: borrowerToken,
      chainlinkLenderFeed: chainlinkLenderFeed,
      chainlinkBorrowerFeed: chainlinkBorrowerFeed,
      totalCollateral: totalCollateral,
      loanAmount: loanAmount,
      loanDuration: loanDuration,
      interestRate: interestRate,
    };

    const tx = await contracts.contractLendingFactory
      .setupLoan(data, v, r, s)
      .catch((err) => setError("*" + err.reason));

    await tx.wait();
  };

  const cancelLendingOffer = async (
    borrower,
    lender,
    lenderToken,
    borrowerToken,
    chainlinkLenderFeed,
    chainlinkBorrowerFeed,
    totalCollateral,
    loanAmount,
    loanDuration,
    interestRate,
    v,
    r,
    s
  ) => {
    const data = {
      borrower: borrower,
      lender: lender,
      lenderToken: lenderToken,
      borrowerToken: borrowerToken,
      chainlinkLenderFeed: chainlinkLenderFeed,
      chainlinkBorrowerFeed: chainlinkBorrowerFeed,
      totalCollateral: totalCollateral,
      loanAmount: loanAmount,
      loanDuration: loanDuration,
      interestRate: interestRate,
    };

    const tx = await contracts.contractLendingFactory.cancelLendingOffer(
      data,
      v,
      r,
      s
    );

    await tx.wait();
  };

  const repayLendingOffer = async (messageData) => {
    const tokenContract = new ethers.Contract(
      messageData.lenderToken,
      ERC20JSON.abi,
      signer
    );

    // TODO: change approve to only approve what's necessary
    const tx = await tokenContract.approve(
      messageData.addressLending,
      ethers.constants.MaxUint256
    );
    await tx.wait();

    const contractLending = new ethers.Contract(
      messageData.addressLending,
      LendingJSON.abi,
      signer
    );

    await contractLending.payLoan().catch((err) => setError("*" + err.reason));
  };

  async function liquidateLendingOffer(messageData) {
    const contractLending = new ethers.Contract(
      messageData.addressLending,
      LendingJSON.abi,
      signer
    );

    const tx = await contractLending
      .liquidate()
      .catch((err) => setError("*" + err.reason));
    await tx.wait();
  }

  const updateLendingStatus = async () => {
    if (Object.keys(messages) > 0 && activeReceiverAddress in messages) {
      let tempMessages = messages;
      let currentMessage;
      let tempCurrentMessage;

      for (let i = 0; i < messages[activeReceiverAddress].length; i++) {
        currentMessage = messages[activeReceiverAddress][i];
        tempCurrentMessage = tempMessages[activeReceiverAddress][i];

        if (currentMessage.messageType === "2") {
          const abiCoder = new ethers.utils.AbiCoder();
          const signature = ethers.utils.keccak256(
            abiCoder.encode(
              ["uint8", "bytes32", "bytes32"],
              [
                currentMessage.message.v,
                currentMessage.message.r,
                currentMessage.message.s,
              ]
            )
          );
          const status =
            await contracts.contractLendingFactory.lendingStatusBySignature(
              signature
            );

          if (status) {
            // arbritary number '-2' to make no button appear. also for cancelled
            tempCurrentMessage.message.offerStatus = -2;
          } else {
            const addressLending =
              await contracts.contractLendingFactory.lendingContractsBySignature(
                signature
              );

            if (addressLending !== BURNER_ADDRESS) {
              const contractLending = new ethers.Contract(
                addressLending,
                LendingJSON.abi,
                signer
              );

              const contractLendingStatus = await contractLending.state();

              const loanStartTime = parseInt(
                (await contractLending.loanStartTime())._hex
              );

              tempCurrentMessage.message.loanStartTime = loanStartTime;
              tempCurrentMessage.message.addressLending = addressLending;
              tempCurrentMessage.message.offerStatus = contractLendingStatus;
            } else {
              tempCurrentMessage.message.offerStatus = -1;
            }
          }
        }
      }
      setMessageLog(tempMessages);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateNFTStatus();
      updateLendingStatus();
      // get current blockchain time
      getCurrentETHTime();
    }, 1000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    const getPrice = async () => {
      await getETHPrice(setETH_USD);
    };

    getPrice();
  }, [activeReceiverAddress]);

  return (
    <>
      <Modal
        isOpen={Boolean(error)}
        onRequestClose={() => {
          setError("");
        }}
        style={modalStyles}
      >
        <code className="text-red-500 text-center">{error}</code>
      </Modal>
      <div className="absolute flex flex-col-reverse overflow-y-scroll w-full bottom-[88px] h-[68vh]">
        <Chat
          ETH_USD={ETH_USD}
          currentETHTime={currentETHTime}
          activeReceiverAddress={activeReceiverAddress}
          messages={messages}
          acceptNFTOffer={acceptNFTOffer}
          cancelOffer={cancelOffer}
          acceptLendingOffer={acceptLendingOffer}
          cancelLendingOffer={cancelLendingOffer}
          repayLendingOffer={repayLendingOffer}
          liquidateLendingOffer={liquidateLendingOffer}
        />
      </div>
    </>
  );
};

export default ChatBox;
