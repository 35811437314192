import { avalancheIconSVG, ethereumIconSVG, polygonIconSVG } from "../assets";

// TODO: find better way to split constants across more relevant folders

export const CONTRACT_META_DATA = {
  43113: {
    oracleWETH: "0x86d67c3D38D2bCeE722E601025C25a575021c6EA",
    oracleUSDC: "0x7898AcCC83587C3C55116c5230C17a6Cd9C71bad",
    contractEcho: "0x796C29b3eA29c0464C055A75Ee168b4f67BC84B7",
    contractTokenTransfer: "0xA370673393CdEf99756F5289Bd03a46d31bdA4d8",
    contractWETH: "0xbA926096A6eF3FEf282059c9fE605707D41c3218",
    contractUSDC: "0x10f0395866108E527e4687Ba9fAfbEB6A2030fF5",
    contractBAYC: "0x7A9472849fD95251C62689A0C4F545e49876eA78",
    contractPFP: "0xb813F9fC180D122e83002aC4d4d6fEB2bCD303B8",
    contractRequestNFT: "0x2D64de00E6c01c4D35FB63dca5Cc6d4F0C2D3493",
    contractLendingFactory: "0x6d2a9e291f2B16Cae36AC6282F031cD7bbAE5deE",
    quickNodeWSURL:
      "wss://red-floral-river.avalanche-testnet.discover.quiknode.pro/2ed753d562dc5ece41d07b1b9a9af1fe7f410bb6/ext/bc/C/ws",
    logo: avalancheIconSVG,
    name: "Avalanche",
    baseToken: "AVAX",
    theGraphAPI: "https://api.thegraph.com/subgraphs/name/mtwichan/echofuji",
  },
  80001: {
    oracleWETH: "0x0715A7794a1dc8e42615F059dD6e406A6594651A",
    oracleUSDC: "0x572dDec9087154dC5dfBB1546Bb62713147e0Ab0",
    contractEcho: "0x78A99062A83715376754F53620468aB47759F540",
    contractTokenTransfer: "0x9c212d9E1b10AEB736B5A296F23ceCd447550a83",
    contractPFP: "0xd8fE633B755B77Eee505832b82B33d9A22102234",
    contractRequestNFT: "0x9E27D3e10987E501225F98BE5CE7E3B9e9524FC6",
    contractLendingFactory: "0x65501B90B5E4B31716d2901955e05320A11EdFcD",
    contractWETH: "0x2a73F7207478bba7a035300073F1A1e5aF0dEB32",
    contractUSDC: "0x3eD3C028F8a659F231de5b7E48641907E525cdB8",
    contractBAYC: "0x2c1E732aDd468AD23f23F422a9d68904E658078a",
    quickNodeWSURL:
      "wss://blissful-greatest-shape.matic-testnet.quiknode.pro/b3c0e3599ce5dea7c7d9f41a500077e9bd5312c4/",
    logo: polygonIconSVG,
    name: "Polygon",
    baseToken: "MATIC",
    theGraphAPI: "https://api.thegraph.com/subgraphs/name/mtwichan/echo",
  },
  5: {
    oracleWETH: "0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e",
    oracleUSDC: "0xAb5c49580294Aff77670F839ea425f5b78ab3Ae7",
    contractEcho: "0x2dF31762eC2f64af81fb1510E430Ec6C37049A8f",
    contractTokenTransfer: "0x6FcC029634D89B32AB920ed42DBE0d87D9a6c6b4",
    contractPFP: "0xB931eDBCd88A9F016457bd96Ba358B45c1562C62",
    contractRequestNFT: "0xC78de9d4487451A37a06AB1Eec635DD073c05C8e",
    contractLendingFactory: "0xa8c65eb5779B895374187A06f8907ccc98d6D034",
    contractWETH: "0x63f3A7dbbA95bAdf4AE3fD012FEb9B18503eCE62",
    contractUSDC: "0xEe0B835d06C6fd0509165264DbE026f988Fb8aF1",
    contractBAYC: "0x1cbDd488961183106556b0095EFa19089ABd78f3",
    quickNodeWSURL:
      "wss://sleek-frequent-sailboat.ethereum-goerli.quiknode.pro/13e597caae3f3db2b5495018ea5666b5d98ec573/",
    logo: ethereumIconSVG,
    name: "Ethereum",
    baseToken: "ETHER",
    theGraphAPI: "https://api.thegraph.com/subgraphs/name/mtwichan/echogoerli",
  },
};

export const BURNER_ADDRESS = "0x0000000000000000000000000000000000000000";

export const ETH_USD_API_ENDPOINT =
  "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD&api_key=58cddf6c19d0f436c15409ad20c236d10ee173c0b77be1ee4f4a1f6b7c53c843";
