import { ethers } from "ethers";
import React, { useState } from "react";
import { Oval } from "react-loader-spinner";

import SendMessageContainer from "./SendMessageContainer";
import ReceiveMessageContainer from "./ReceiveMessageContainer";

import { CONTRACT_META_DATA } from "../../../constants";
import { ethereumIconSVG, usdcIconSVG } from "../../../assets";

const formatSignificantDigits = (value) => {
  if (value >= 1) {
    value = parseFloat(value).toFixed(2);
  } else {
    value = parseFloat(value).toPrecision(3);
  }
  return value;
};

function Chat({
  ETH_USD,
  currentETHTime,
  activeReceiverAddress,
  messages,
  acceptNFTOffer,
  cancelOffer,
  acceptLendingOffer,
  cancelLendingOffer,
  repayLendingOffer,
  liquidateLendingOffer,
}) {
  const [isNFTTransactionLoading, setIsNFTTranscationLoading] = useState({
    loading: false,
    activeIndex: -1,
  });
  // TODO: use this state to add P2P loading for buttons
  const [isP2PTransactionLoading, setIsP2PTranscationLoading] = useState({
    loading: false,
    activeIndex: -1,
  });

  const chatJSX = [];

  const chainId = parseInt(window.ethereum.chainId);

  if (activeReceiverAddress == null) {
    return [];
  }

  const activeReceiverAddressLowerCase = activeReceiverAddress.toLowerCase();
  let messageLog = [];
  if (activeReceiverAddress in messages) {
    messageLog = messages[activeReceiverAddress];
  }

  if (messageLog == null) {
    return [];
  }

  for (let idx = messageLog.length; idx > 0; idx--) {
    let messageMetaData = messageLog[idx - 1];
    let message = messageMetaData.message;
    let messageData = message;
    let timestamp = messageMetaData.timestamp;
    let messageType = messageMetaData.messageType;
    if (activeReceiverAddressLowerCase === messageMetaData.from) {
      if (messageType === "1") {
        let expirationTime;
        let priceFormatted;

        expirationTime = (messageData.timeExpiry - currentETHTime) / 86400;
        expirationTime = formatSignificantDigits(expirationTime);

        priceFormatted = ethers.utils.formatEther(messageData.tokenAmount);
        priceFormatted = formatSignificantDigits(priceFormatted);

        message = (
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-white text-sm font-bold rounded-[4px] bg-[#000000] py-1 w-[85px]">
                NFT Offer
              </div>
              {messageData.offerStatus === 1 ? (
                <div className="flex justify-center items-center text-white text-sm bg-[#27AE60] rounded-[20px] px-3">
                  Accepted
                </div>
              ) : messageData.offerStatus === 2 ? (
                <div className="flex justify-center items-center text-white text-sm bg-[#ff0a00] rounded-[20px] px-3">
                  Cancelled
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="flex flex-col text-sm">
              <div className="font-bold">From</div>
              <code>{messageData.buyer}</code>
            </div>
            <div className="flex flex-row gap-16">
              <div className="flex flex-col text-sm">
                <div className="font-bold">Price</div>
                <code>
                  {priceFormatted} WETH{" "}
                  <span className="opacity-50">{`($${(
                    ETH_USD *
                    parseFloat(
                      ethers.utils.formatEther(
                        ethers.BigNumber.from(messageData.tokenAmount)
                      )
                    )
                  ).toLocaleString()} USD)`}</span>
                </code>
              </div>
              <div className="flex flex-col text-sm">
                {messageData.offerStatus === 1 ? (
                  <>
                    <div className="font-bold">Expires</div>
                    {expirationTime > 0 ? (
                      <code>{`${expirationTime} Days`}</code>
                    ) : (
                      <code>Expired</code>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="flex flex-row gap-[12px]">
              {messageData.imageURL ? (
                <img
                  className="h-[50px] rounded-[5px]"
                  src={messageData.imageURL}
                  alt=""
                ></img>
              ) : (
                <div className="bg-black w-[50px] h-[50px] rounded-[5px]"></div>
              )}
              <div className="flex flex-col text-left">
                <div className="text-lg font-medium">
                  {messageData.name} #{messageData.tokenId}
                </div>
                <div className="text-sm font-medium">
                  Address{" "}
                  {`${messageData.NFTAddress.substring(
                    0,
                    4
                  )}...${messageData.NFTAddress.substring(38)}`}
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              {!Boolean(messageData.offerStatus) ||
              messageData.offerStatus === 0 ? (
                isNFTTransactionLoading.loading &&
                idx === isNFTTransactionLoading.activeIndex ? (
                  <Oval
                    ariaLabel="loading-indicator"
                    height={40}
                    width={40}
                    strokeWidth={3}
                    strokeWidthSecondary={3}
                    color="black"
                    secondaryColor="white"
                  ></Oval>
                ) : (
                  <button
                    className="bg-[#27AE60] text-white rounded-[8px] py-2 w-[150px]"
                    onClick={async () => {
                      await setIsNFTTranscationLoading({
                        loading: true,
                        activeIndex: idx,
                      });
                      acceptNFTOffer(
                        ethers.BigNumber.from(
                          messageData.tokenAmount.hex
                        ).toString(),
                        messageData.tokenId,
                        messageData.timeExpiry,
                        messageData.buyer,
                        messageData.seller,
                        messageData.tokenAddress,
                        messageData.NFTAddress,
                        messageData.v,
                        messageData.r,
                        messageData.s
                      )
                        .catch((error) => {
                          setIsNFTTranscationLoading({
                            loading: false,
                            activeIndex: -1,
                          });
                        })
                        .then(() =>
                          setIsNFTTranscationLoading({
                            loading: false,
                            activeIndex: -1,
                          })
                        );
                    }}
                    disabled={isNFTTransactionLoading.loading}
                  >
                    Accept
                  </button>
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      } else if (messageType === "2") {
        // TODO: get start time from contract and do calculation ((loan_duration - start_time + eth_time) / 86400
        let loanAmountFormatted;
        let maturityTime;
        let collateralFormatted;
        let APYFormatted;
        const overAllMaturityTime = messageData.loanDuration / 86400;

        loanAmountFormatted = formatSignificantDigits(
          ethers.utils.formatEther(messageData.loanAmount)
        );
        loanAmountFormatted =
          messageData.lenderToken === CONTRACT_META_DATA[chainId].contractWETH
            ? loanAmountFormatted + " WETH"
            : loanAmountFormatted + " USDC";

        if (messageData.loanStartTime == null) {
          maturityTime = formatSignificantDigits(overAllMaturityTime);
          maturityTime = `${maturityTime} Days`;
        } else if (
          messageData.loanStartTime + messageData.loanDuration >
          currentETHTime
        ) {
          maturityTime =
            (messageData.loanStartTime +
              messageData.loanDuration -
              currentETHTime) /
            86400;
          maturityTime = formatSignificantDigits(maturityTime);
          maturityTime = `${maturityTime} Days`;
        } else {
          maturityTime = "Expired";
        }

        collateralFormatted = formatSignificantDigits(
          ethers.utils.formatEther(messageData.totalCollateral)
        );
        collateralFormatted =
          messageData.lenderToken === CONTRACT_META_DATA[chainId].contractWETH
            ? `${collateralFormatted} USDC`
            : `${collateralFormatted} WETH`;
        APYFormatted =
          messageData.interestRate === 0
            ? 0
            : (parseFloat(
                ethers.utils.formatEther(messageData.interestRate.hex)
              ) *
                365) /
              parseFloat(overAllMaturityTime);

        APYFormatted = formatSignificantDigits(APYFormatted);

        message = (
          <div className="flex flex-col gap-3">
            <div className="flex justify-center items-center text-white text-sm font-bold rounded-[4px] bg-[#000000] py-1 w-[110px]">
              Lending Offer
            </div>
            <div className="flex gap-3">
              <img
                className="h-[80px]"
                src={
                  messageData.lenderToken ===
                  CONTRACT_META_DATA[chainId].contractWETH
                    ? ethereumIconSVG
                    : usdcIconSVG
                }
                alt=""
              ></img>
              <div className="flex flex-col text-sm">
                <div className="flex">
                  <div className="text-[#828282]">Amount</div>&nbsp;
                  <div className="font-bold">{loanAmountFormatted}</div>
                </div>
                <div className="flex">
                  <div className="text-[#828282]">APY</div>&nbsp;
                  <div className="font-medium">{APYFormatted}%</div>
                </div>
                <div className="flex">
                  <div className="text-[#828282]">Maturity</div>&nbsp;
                  <div className="font-medium">{maturityTime}</div>
                </div>
                <div className="flex">
                  <div className="text-[#828282]">Collateral</div>&nbsp;
                  <div className="font-medium">{collateralFormatted}</div>
                </div>
              </div>
            </div>
            {messageData.offerStatus === -1 ? (
              <button
                className="bg-gradient-to-r from-[#00FFD1] to-[#FF007A] via-[#9b649c] rounded-[40px] w-[350px] text-white py-[10px] disabled:opacity-50 font-bold"
                onClick={() => {
                  acceptLendingOffer(
                    messageData.borrower,
                    messageData.lender,
                    messageData.lenderToken,
                    messageData.borrowerToken,
                    messageData.chainlinkLenderFeed,
                    messageData.chainlinkBorrowerFeed,
                    ethers.BigNumber.from(
                      messageData.totalCollateral.hex
                    ).toString(),
                    ethers.BigNumber.from(
                      messageData.loanAmount.hex
                    ).toString(),
                    messageData.loanDuration,
                    messageData.interestRate,
                    messageData.v,
                    messageData.r,
                    messageData.s
                  );
                }}
              >
                Accept
              </button>
            ) : messageData.offerStatus === 0 ? (
              <button
                className="bg-[#27AE60] rounded-[40px] w-[350px] text-white py-[10px] disabled:opacity-50 font-bold"
                onClick={() => {
                  repayLendingOffer(messageData);
                }}
              >
                Repay
              </button>
            ) : (
              <></>
            )}
          </div>
        );
      }

      if (currentETHTime !== 0) {
        chatJSX.push(
          <ReceiveMessageContainer
            receiverAddress={activeReceiverAddress}
            message={message}
            timestamp={timestamp}
            key={idx}
          />
        );
      }
    } else {
      if (messageType === "1") {
        let expirationTime;
        let priceFormatted;

        expirationTime = (messageData.timeExpiry - currentETHTime) / 86400;
        expirationTime = formatSignificantDigits(expirationTime);

        priceFormatted = ethers.utils.formatEther(messageData.tokenAmount);
        priceFormatted = formatSignificantDigits(priceFormatted);

        message = (
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-white text-sm font-bold rounded-[4px] bg-[#000000] py-1 w-[85px]">
                NFT Offer
              </div>
              {messageData.offerStatus === 1 ? (
                <div className="flex justify-center items-center text-white text-sm bg-[#27AE60] rounded-[20px] px-3">
                  Accepted
                </div>
              ) : messageData.offerStatus === 2 ? (
                <div className="flex justify-center items-center text-white text-sm bg-[#ff0a00] rounded-[20px] px-3">
                  Cancelled
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="flex flex-col text-sm">
              <div className="font-bold">To</div>
              <code>{messageData.seller}</code>
            </div>
            <div className="flex flex-row gap-16">
              <div className="flex flex-col text-sm">
                <div className="font-bold">Price</div>
                <code>
                  {priceFormatted} WETH{" "}
                  <span className="opacity-50">{`($${(
                    ETH_USD *
                    parseFloat(
                      ethers.utils.formatEther(
                        ethers.BigNumber.from(messageData.tokenAmount)
                      )
                    )
                  ).toLocaleString()} USD)`}</span>
                </code>
              </div>
              <div className="flex flex-col text-sm">
                {messageData.offerStatus === 1 ? (
                  <>
                    <div className="font-bold">Expires</div>
                    {expirationTime > 0 ? (
                      <code>{`${expirationTime} Days`}</code>
                    ) : (
                      <code>Expired</code>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="flex flex-row gap-[12px]">
              {messageData.imageURL ? (
                <img
                  className="h-[50px] rounded-[5px]"
                  src={messageData.imageURL}
                  alt=""
                ></img>
              ) : (
                <div className="bg-black w-[50px] h-[50px] rounded-[5px]"></div>
              )}
              <div className="flex flex-col text-left">
                <div className="text-lg font-medium">
                  {messageData.name} #{messageData.tokenId}
                </div>
                <div className="text-sm font-medium">
                  Address{" "}
                  {`${messageData.NFTAddress.substring(
                    0,
                    4
                  )}...${messageData.NFTAddress.substring(38)}`}
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              {!Boolean(messageData.offerStatus) ||
              messageData.offerStatus === 0 ? (
                isNFTTransactionLoading.loading &&
                idx === isNFTTransactionLoading.activeIndex ? (
                  <Oval
                    ariaLabel="loading-indicator"
                    height={40}
                    width={40}
                    strokeWidth={3}
                    strokeWidthSecondary={3}
                    color="black"
                    secondaryColor="white"
                  ></Oval>
                ) : (
                  <button
                    className="bg-[#ff0a00] text-white rounded-[8px] py-2 w-[150px]"
                    onClick={async () => {
                      await setIsNFTTranscationLoading({
                        loading: true,
                        activeIndex: idx,
                      });
                      cancelOffer(
                        ethers.BigNumber.from(
                          messageData.tokenAmount.hex
                        ).toString(),
                        messageData.tokenId,
                        messageData.timeExpiry,
                        messageData.buyer,
                        messageData.seller,
                        messageData.tokenAddress,
                        messageData.NFTAddress,
                        messageData.v,
                        messageData.r,
                        messageData.s
                      )
                        .catch((error) =>
                          setIsNFTTranscationLoading({
                            loading: false,
                            activeIndex: -1,
                          })
                        )
                        .then(() =>
                          setIsNFTTranscationLoading({
                            loading: false,
                            activeIndex: -1,
                          })
                        );
                    }}
                    disabled={isNFTTransactionLoading.loading}
                  >
                    Cancel
                  </button>
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      } else if (messageType === "2") {
        // Formatting these values to 2 decimal places or 3 decimal places if number is less than 1
        let loanAmountFormatted;
        let maturityTime;
        let collateralFormatted;
        let APYFormatted;
        const overAllMaturityTime = messageData.loanDuration / 86400;
        loanAmountFormatted = formatSignificantDigits(
          ethers.utils.formatEther(messageData.loanAmount)
        );
        loanAmountFormatted =
          messageData.lenderToken === CONTRACT_META_DATA[chainId].contractWETH
            ? loanAmountFormatted + " WETH"
            : loanAmountFormatted + " USDC";

        if (messageData.loanStartTime == null) {
          maturityTime = formatSignificantDigits(overAllMaturityTime);
          maturityTime = `${maturityTime} Days`;
        } else if (
          messageData.loanStartTime + messageData.loanDuration >
          currentETHTime
        ) {
          maturityTime =
            (messageData.loanStartTime +
              messageData.loanDuration -
              currentETHTime) /
            86400;
          maturityTime = formatSignificantDigits(maturityTime);
          maturityTime = `${maturityTime} Days`;
        } else {
          maturityTime = "Expired";
        }
        collateralFormatted = formatSignificantDigits(
          ethers.utils.formatEther(messageData.totalCollateral)
        );
        collateralFormatted =
          messageData.lenderToken === CONTRACT_META_DATA[chainId].contractWETH
            ? `${collateralFormatted} USDC`
            : `${collateralFormatted} WETH`;

        APYFormatted =
          messageData.interestRate === 0
            ? 0
            : (parseFloat(
                ethers.utils.formatEther(messageData.interestRate.hex)
              ) *
                365) /
              parseFloat(overAllMaturityTime);

        APYFormatted = formatSignificantDigits(APYFormatted);

        message = (
          <div className="flex flex-col gap-3">
            <div className="flex justify-center items-center text-white text-sm font-bold rounded-[4px] bg-[#000000] py-1 w-[110px]">
              Lending Offer
            </div>
            <div className="flex gap-3">
              <img
                className="h-[80px]"
                src={
                  messageData.lenderToken ===
                  CONTRACT_META_DATA[chainId].contractWETH
                    ? ethereumIconSVG
                    : usdcIconSVG
                }
                alt=""
              ></img>
              <div className="flex flex-col text-sm">
                <div className="flex">
                  <div className="text-[#828282]">Amount</div>&nbsp;
                  <div className="font-bold">{loanAmountFormatted}</div>
                </div>
                <div className="flex">
                  <div className="text-[#828282]">APY</div>&nbsp;
                  <div className="font-medium">{APYFormatted}%</div>
                </div>
                <div className="flex">
                  <div className="text-[#828282]">Maturity</div>&nbsp;
                  <div className="font-medium">{maturityTime}</div>
                </div>
                <div className="flex">
                  <div className="text-[#828282]">Collateral</div>&nbsp;
                  <div className="font-medium">{collateralFormatted}</div>
                </div>
              </div>
            </div>
            {messageData.offerStatus === -1 ? (
              <button
                className="bg-[#ff0a00] rounded-[40px] w-[350px] text-white py-[10px] disabled:opacity-50 font-bold"
                onClick={() => {
                  cancelLendingOffer(
                    messageData.borrower,
                    messageData.lender,
                    messageData.lenderToken,
                    messageData.borrowerToken,
                    messageData.chainlinkLenderFeed,
                    messageData.chainlinkBorrowerFeed,
                    ethers.BigNumber.from(
                      messageData.totalCollateral.hex
                    ).toString(),
                    ethers.BigNumber.from(
                      messageData.loanAmount.hex
                    ).toString(),
                    messageData.loanDuration,
                    messageData.interestRate,
                    messageData.v,
                    messageData.r,
                    messageData.s
                  );
                }}
              >
                Cancel
              </button>
            ) : messageData.offerStatus === 0 ? (
              <button
                className="bg-[#ff0a00] rounded-[40px] w-[350px] text-white py-[10px] disabled:opacity-50 font-bold"
                onClick={() => {
                  liquidateLendingOffer(messageData);
                }}
              >
                Liquidate
              </button>
            ) : (
              <></>
            )}
          </div>
        );
      }

      if (currentETHTime !== 0) {
        chatJSX.push(
          <SendMessageContainer
            message={message}
            timestamp={timestamp}
            key={idx}
          />
        );
      }
    }
  }
  return chatJSX;
}

export default Chat;
