import { CONTRACT_META_DATA } from "../constants";
import { createClient } from "urql";
import "isomorphic-unfetch"; // required for urql: https://github.com/FormidableLabs/urql/issues/283

// used in functions that are not react components or hooks
export const theGraphClient = () => {
  // TODO: replace this with a chainId function parameter -> need to make it so theGraphClient is called only once
  const chainID = parseInt(window.ethereum.networkVersion);
  const graphApiUrl = CONTRACT_META_DATA[chainID].theGraphAPI;
  const graphClient = createClient({
    url: graphApiUrl,
    requestPolicy: 'cache-and-network'
  });
  return graphClient;
};
