import { CONTRACT_META_DATA } from "../constants";
import { createClient } from "urql";
import "isomorphic-unfetch"; // required for urql: https://github.com/FormidableLabs/urql/issues/283
import { useNetwork } from "wagmi";

export const useTheGraphClient = () => {
  const { chain } = useNetwork();
  if (chain.id in CONTRACT_META_DATA) {
    const graphApiUrl = CONTRACT_META_DATA[chain.id].theGraphAPI;
    const graphClient = createClient({
      url: graphApiUrl,
      requestPolicy: 'cache-and-network'
    });
    return graphClient;
  }
  return null
};
